import * as React from 'react'
import Block from './common/block'

const Benefits = (props: any) => {
    const data = props.data
    const className = props.className ?  props.className : ''
    return (
        <>
            <Block className={'benefits-block text-center  ' + props.background + ' ' + className }   >
                <div className="container">
                    <ul className="list-check row">
                        {
                            data.benefits.map((dt: any)=>(
                                <li className="list-check__item text-center pt-0 col-md-4 col-lg-2" key={dt.id}> 
                                    {/* <div className="list-check__icon mb-4">
                                        <i className="icon-check"></i>
                                    </div> */}
                                     <div  className="list-check-image mb-4 ratio ratio-1x1">
                                        <img src={dt.image} alt=''  />
                                    </div>
                                    <div className="list-check__text">
                                        {dt.title}
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </Block>
        </>
    )
}

export default Benefits
