import * as React from 'react'
import VideoBox from './common/video-box'
import styled from 'styled-components'
import Block from './common/block'

const Title = styled.h4 `
    padding: 4rem 0;
    margin: 0;
`
const VideoBlock = (props: any) => {
    return (
        <>
            <Block className={'video-block text-center ' + props.background}  >
                <div className='container'>
                    <Title>{props.title}</Title>
                    <div className='row justify-content-center'>
                        <div className='col-lg-8'>
                            <VideoBox video={props.video} title={props.title} thumbnail={props.thumbnail}></VideoBox>
                        </div>
                    </div>
                </div>
            </Block> 
        </>
    )
}

export default VideoBlock
