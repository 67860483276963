import * as React from 'react'
import Block from './common/block' 
import CardImage from './common/card-image'
import styled from 'styled-components'
import { breakpointLg, breakpointMd} from '../design/responsive/breakpoint'
const Container = styled.div`
    position: relative;
    height:0;
    padding-bottom: 34%;
    overflow: hidden;
    
    @media (max-width: ${breakpointLg}) {
      padding-bottom: 42%;
    }
    @media (max-width: ${breakpointMd}) {
      padding-bottom: 56%
    }
    .masory__item {
      & + .masory__item {
        margin-top: 2rem;
        @media (max-width: ${breakpointLg}) {
          margin-top: 1rem;
        }
    }  
  `;
  const Row = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% + 20%);
    @media (min-width: ${breakpointLg} ) {
      margin-left: -1rem!important;
      margin-right: -1rem!important;
      > div {
          padding-left: 1rem!important;
          padding-right: 1rem!important;
      }
    }
    img {
      width: 100%;
      border-radius: 1.8rem;
    } 
  }
  `;
  const Col = styled.div`
    position: relative;
    &:nth-child(1) {
        top: 18rem
    }
    &:nth-child(2) {
      top: 0
    }
    &:nth-child(3) {
        top: -4.8rem
    }
    &:nth-child(4) {
        top: 18rem
    }
    &:nth-child(6) {
        top: 6.5rem
    }
    @media (max-width: 1336px) {
      &:nth-child(1) {
        top: 15rem
      }
      
      &:nth-child(4) {
          top: 15rem
      }
    }

  @media (max-width: 1280px) {
      &:nth-child(1) {
          top: 12rem
      }

      &:nth-child(4) {
          top: 12rem
      }
    }
  @media (max-width: ${breakpointLg}) {
    &:nth-child(1) {
        top: 6.5rem
    }
    &:nth-child(2) {
        top: 1rem
    }
    &:nth-child(3) {
        top: -3rem
    }
    &:nth-child(4) {
        top: 6.5rem
    }
  }
  @media (max-width: ${breakpointMd}) {
    &:nth-child(6),&:nth-child(5) {
      display: none;
    }
  }
  `;
  
const Masory = (props: any) => {
  return ( 
      <>
          <Block className={' p-0  masory '}  >
            <Container>
              <Row className='row g-3'>
                  <>
                  <Col className='col col-md-2 '>
                    <CardImage className='masory__item' src='/images/240x240-grey.png' alt='' ratio='1x1' />
                    <CardImage className='masory__item' src='/images/240x240-blue.png' alt='' ratio='1x1' />
                    <CardImage className='masory__item' src='/images/240x240-grey.png' alt='' ratio='1x1' />
                  </Col>
                  <Col className='col col-md-2 '>
                    <CardImage className='masory__item' src='/images/240x240-yellow.png' alt='' ratio='1x1' />
                    <CardImage className='masory__item' src='/images/240x240-grey.png' alt='' ratio='1x1' />
                    <CardImage className='masory__item' src='/images/240x240-red.png' alt='' ratio='1x1' />
                  </Col>
                  <Col className='col col-md-2 '>
                    <CardImage className='masory__item' src='/images/240x240-blue.png' alt='' ratio='1x1' />
                    <CardImage className='masory__item' src='/images/240x240-grey.png' alt='' ratio='1x1' />
                    <CardImage className='masory__item' src='/images/240x240-grey.png' alt='' ratio='1x1' />
                  </Col>
                  <Col className='col col-md-2 '>
                    <CardImage className='masory__item' src='/images/240x240-grey.png' alt='' ratio='1x1' />
                    <CardImage className='masory__item' src='/images/240x240-grey.png' alt='' ratio='1x1' />
                    <CardImage className='masory__item' src='/images/240x240-grey.png' alt='' ratio='1x1' />
                  </Col>
                  <Col className='col col-md-2 '>
                    <CardImage className='masory__item' src='/images/240x240-red.png' alt='' ratio='1x1' />
                    <CardImage className='masory__item' src='/images/240x240-grey.png' alt='' ratio='1x1' />
                    <CardImage className='masory__item' src='/images/240x240-blue.png' alt='' ratio='1x1' />
                  </Col>
                  <Col className='col col-md-2 '>
                    <CardImage className='masory__item' src='/images/240x240-grey.png' alt='' ratio='1x1' />
                    <CardImage className='masory__item' src='/images/240x240-grey.png' alt='' ratio='1x1' />
                    <CardImage className='masory__item' src='/images/240x240-yellow.png' alt='' ratio='1x1' />
                  </Col>
                  </>
                </Row>
            </Container>
          </Block> 
      </>
  )
}

export default Masory
